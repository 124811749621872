import React, { useState } from "react"
import { Form } from "semantic-ui-react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import SingleColumnBlock from "../components/singleColumnBlock"

import ResourceImages from "@components/ResourceImages"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  DownloadProductBrochureButton,
} from "../components/buttons"

const Faq = () => {
  const [searchVal, setSearchVal] = useState("")

  const filteredItems = [...items].filter(
    f =>
      f.title.toLowerCase().includes(searchVal.toLowerCase()) ||
      (f.raw_body && f.raw_body.toLowerCase().includes(searchVal.toLowerCase()))
  )

  return (
    <PageLayout>
      <SEO title="Frequently Asked Questions | ArtVue Glass" ogDescription="" />
      <PageWrapper color="grey">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h1>Frequently Asked Questions</h1>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper>
        <Form>
          <Form.Input
            placeholder="Search"
            value={searchVal}
            onChange={e => setSearchVal(e.target.value)}
          />
        </Form>
        <div>
          {filteredItems.map(({ key, title, body }) => (
            <div key={key} style={{ marginBottom: "1.5em" }}>
              <h2>{title}</h2>
              <div>{body}</div>
            </div>
          ))}
        </div>
        {filteredItems.length === 0 && <p>No results.</p>}
      </PageWrapper>
    </PageLayout>
  )
}

export default Faq

const items = [
  {
    title: "What is decorative architectural glass?",
    raw_body: `Glass that is used for more than just a functional
          purpose—particularly if it is designed to be pretty or change the look
          of the space in which it is used—is decorative glass (also known as
          ornamental glass). The use of decorative glass can range from frosted, colored or
          textured glass doors (especially shower doors) to stairways, hand
          rails, kitchen backsplashes, tables and walls. Furniture made of glass
          is classified as decorative glass, as are shelves and structural glass
          floors. Various options exist for different colors, transparencies, surface
          structures, reflectivity and opacity levels are available. Ranging
          from clear and colored float glass, mirrors, painted glass, up to and
          including glass with a satin touch or optical features.`,
    body: (
      <>
        <p>
          Glass that is used for more than just a functional
          purpose—particularly if it is designed to be pretty or change the look
          of the space in which it is used—is decorative glass (also known as
          ornamental glass).{" "}
        </p>
        <p>
          The use of decorative glass can range from frosted, colored or
          textured glass doors (especially shower doors) to stairways, hand
          rails, kitchen backsplashes, tables and walls. Furniture made of glass
          is classified as decorative glass, as are shelves and structural glass
          floors.
        </p>
        <p>
          Various options exist for different colors, transparencies, surface
          structures, reflectivity and opacity levels are available. Ranging
          from clear and colored float glass, mirrors, painted glass, up to and
          including glass with a satin touch or optical features.
        </p>
      </>
    ),
  },
  {
    title: "What is monolithic glass?",
    raw_body: `Monolithic glass consists of a single sheet of architectural glass and
          comes in various thickness and sizes. The term does not apply to
          laminated glass.`,
    body: (
      <>
        <p>
          Monolithic glass consists of a single sheet of architectural glass and
          comes in various thickness and sizes. The term does not apply to
          laminated glass.
        </p>
      </>
    ),
  },
  {
    title: "What is float glass?",
    raw_body: `Float glass is the initial product resulting from “floating” a ribbon
          of molten glass over a pool of liquid tin. Glass is floated to create
          annealed monolithic glass that is nearly defect free, with a uniform
          thickness, and with a very smooth surface finish. Depending on the raw
          materials melted down in the process, float glass is clear (with a
          greenish hue you can see on the edges), low-iron (no color and nearly
          white), or tinted such as blue or dark green. The quality standards
          for float glass are defined by ASTM C 1036 – Standard Specification
          for Flat Glass.`,
    body: (
      <>
        <p>
          Float glass is the initial product resulting from “floating” a ribbon
          of molten glass over a pool of liquid tin. Glass is floated to create
          annealed monolithic glass that is nearly defect free, with a uniform
          thickness, and with a very smooth surface finish. Depending on the raw
          materials melted down in the process, float glass is clear (with a
          greenish hue you can see on the edges), low-iron (no color and nearly
          white), or tinted such as blue or dark green. The quality standards
          for float glass are defined by ASTM C 1036 – Standard Specification
          for Flat Glass.
        </p>
      </>
    ),
  },
  {
    title: "What is green glass?",
    raw_body: `Traditional float glass consists of silica sand, soda ash, limestone,
          dolomite, and recycled cullet glass. The silica sand used has about
          0.10% ferric oxide (iron content) which gives float glass its greenish
          tint. When looking through normal float glass, such as in residential
          or commercial windows, it is very difficult to see the greenish tint.
          However if you look at the edge of traditional float glass, it is
          easier to see the green tint.`,
    body: (
      <>
        <p>
          Traditional float glass consists of silica sand, soda ash, limestone,
          dolomite, and recycled cullet glass. The silica sand used has about
          0.10% ferric oxide (iron content) which gives float glass its greenish
          tint. When looking through normal float glass, such as in residential
          or commercial windows, it is very difficult to see the greenish tint.
          However if you look at the edge of traditional float glass, it is
          easier to see the green tint.
        </p>
      </>
    ),
  },
  {
    title: "What is low-iron glass?",
    raw_body: `Low-iron glass is near-colorless and is also referred to as
          “optically-clear” glass. As the name suggests, low-iron glass has ten
          times less iron content than traditional float glass. Low-iron glass
          is excellent for back-painted, color-laminated glass applications,
          display cases, shelving, decorative applications when exposed edges
          are visible because it renders the truest colors.
          Low-iron glass can be treated the same way as clear float glass, being
          tempered during manufacturing or laminated afterward.`,
    body: (
      <>
        <p>
          Low-iron glass is near-colorless and is also referred to as
          “optically-clear” glass. As the name suggests, low-iron glass has ten
          times less iron content than traditional float glass. Low-iron glass
          is excellent for back-painted, color-laminated glass applications,
          display cases, shelving, decorative applications when exposed edges
          are visible because it renders the truest colors.
        </p>

        <p>
          Low-iron glass can be treated the same way as clear float glass, being
          tempered during manufacturing or laminated afterward.
        </p>
      </>
    ),
  },
  {
    title: "What is annealed glass? What is annealing?",
    raw_body: `Annealed glass is glass that is slowly cooled during the manufacturing
          process to prevent residual stresses from forming, allowing it to be
          easily handled and processed for cutting, grinding, drilling and other
          fabrication steps. When broken, annealed glass will break into large
          pieces, often with very sharp edges and can cause significant injury
          to people or damage to property nearby.
          Annealed glass is not as strong as tempered or heat-strengthened glass
          of the same thickness. Annealed glass is not safety glass.`,
    body: (
      <>
        <p>
          Annealed glass is glass that is slowly cooled during the manufacturing
          process to prevent residual stresses from forming, allowing it to be
          easily handled and processed for cutting, grinding, drilling and other
          fabrication steps. When broken, annealed glass will break into large
          pieces, often with very sharp edges and can cause significant injury
          to people or damage to property nearby.
        </p>

        <p>
          Annealed glass is not as strong as tempered or heat-strengthened glass
          of the same thickness. Annealed glass is not safety glass.
        </p>
      </>
    ),
  },
  {
    title:
      "What is heat-strengthened glass? Is heat-strengthened glass safety glass?",
    raw_body: `Heat-strengthened glass is intended for general glazing, where
          additional strength is desired. It is approximately two times stronger
          than regular annealed glass of the same thickness. It features a
          surface compression between 3,500 and 7,500 psi and an edge
          compression of 5,500 psi or greater. Heat-strengthened glass break
          pattern is like annealed glass. Heat-strengthened glass is not safety
          glass.`,
    body: (
      <>
        <p>
          Heat-strengthened glass is intended for general glazing, where
          additional strength is desired. It is approximately two times stronger
          than regular annealed glass of the same thickness. It features a
          surface compression between 3,500 and 7,500 psi and an edge
          compression of 5,500 psi or greater. Heat-strengthened glass break
          pattern is like annealed glass. Heat-strengthened glass is not safety
          glass.
        </p>
      </>
    ),
  },
  {
    title: "What is tempered glass? Is tempered glass safety glass?",
    raw_body: `Tempered glass is cooled very quickly during the manufacturing process to increase the strength of the glass. By cooling hot glass rapidly in a process called quenching, tempered glass has very high internal stresses – making it stronger than annealed glass. The surface of tempered glass is approximately four times stronger than the surface of regular annealed glass of the same thickness. In addition to being stronger than annealed glass, tempered glass shatters into small pieces when broken, instead of large shards like annealed glass. Tempered glass is the opposite of annealed glass. Tempered glass is safety glass if it meets: Requirements for ANSI Z97.1 and/or CPSC 16 CFR 1201 and is typically labeled with a “safety” logo identifying the fabricator, glass type, and the standard it meets. The Safety Glazing Certification Council (SGCC) is the largest independent third-party that inspects and test tempered glass to confirm the manufacturing process and result. The SGCC is a voluntary organization. Glass manufacturers/fabricators that pass SGCC requirements are able to etch the SGCC logo permanently onto the glass, as notification that the glass has been properly produced. Tempered glass is required as safety glazing in “hazardous” applications, such as floor-to-ceiling partition walls, entrance doors, sidelites, etc. Tempered glass cannot be further processed (i.e., cut, drilled, notched) but can be back painted and/or laminated with other glass. Tempered glass is also referred to as “toughened” or “fully tempered” glass.`,
    body: (
      <>
        <p>
          Tempered glass is cooled very quickly during the manufacturing process
          to increase the strength of the glass. By cooling hot glass rapidly in
          a process called quenching, tempered glass has very high internal
          stresses – making it stronger than annealed glass. The surface of
          tempered glass is approximately four times stronger than the surface
          of regular annealed glass of the same thickness.
        </p>

        <p>
          In addition to being stronger than annealed glass, tempered glass
          shatters into small pieces when broken, instead of large shards like
          annealed glass. Tempered glass is the opposite of annealed glass.
          Tempered glass is safety glass if it meets:
        </p>

        <ul>
          <li>
            Requirements for ANSI Z97.1 and/or CPSC 16 CFR 1201 and is typically
            labeled with a “safety” logo identifying the fabricator, glass type,
            and the standard it meets.
          </li>

          <li>
            The Safety Glazing Certification Council (SGCC) is the largest
            independent third-party that inspects and test tempered glass to
            confirm the manufacturing process and result. The SGCC is a
            voluntary organization. Glass manufacturers/fabricators that pass
            SGCC requirements are able to etch the SGCC logo permanently onto
            the glass, as notification that the glass has been properly
            produced.
          </li>
        </ul>

        <p>
          Tempered glass is required as safety glazing in “hazardous”
          applications, such as floor-to-ceiling partition walls, entrance
          doors, sidelites, etc.
        </p>

        <p>
          Tempered glass cannot be further processed (i.e., cut, drilled,
          notched) but can be back painted and/or laminated with other glass.
          Tempered glass is also referred to as “toughened” or “fully tempered”
          glass.
        </p>
      </>
    ),
  },
  {
    title: "What is safety glass?",
    raw_body: `Safety glass is tempered glass (also referred to as “toughened” or
          “fully tempered” glass) and/or laminated glass that meets the
          requirements for ANSI Z97.1 and the federal safety standard CPSC 16
          CFR Part 1201 (Consumer Product Safety Commission Safety Standard for
          Architectural Glazing Materials). Glass is a breakable material. If it breaks into sharp pieces, or
          shards, it can injure people and pets. Safety glass improves occupant
          safety and well-being by significantly reducing this risk. In the case
          of tempered glass, if broken, it exhibits a characteristic “dice”
          break pattern of many small pieces that minimize the risk of injury.
          In the case of laminated glass, if broken, it remains in place, held
          together by the plastic or resin interlayer at its core.`,
    body: (
      <>
        <p>
          Safety glass is tempered glass (also referred to as “toughened” or
          “fully tempered” glass) and/or laminated glass that meets the
          requirements for ANSI Z97.1 and the federal safety standard CPSC 16
          CFR Part 1201 (Consumer Product Safety Commission Safety Standard for
          Architectural Glazing Materials).
        </p>

        <p>
          Glass is a breakable material. If it breaks into sharp pieces, or
          shards, it can injure people and pets. Safety glass improves occupant
          safety and well-being by significantly reducing this risk. In the case
          of tempered glass, if broken, it exhibits a characteristic “dice”
          break pattern of many small pieces that minimize the risk of injury.
          In the case of laminated glass, if broken, it remains in place, held
          together by the plastic or resin interlayer at its core.
        </p>
      </>
    ),
  },
  {
    title: "Where should safety glass be used?",
    raw_body: `The Consumer Products Safety Commission establishes the federal standards and requirements for safety architectural glass. Federal safety glazing law requires the use of safety glass in defined hazardous locations, designated by the Model Building Codes. Generally, these include: Bath and shower enclosures All doors, including storm doors, sliding doors, and combination doors Sidelites, including and any glazing next to a door or an opening for human passage, if the nearest exposed edge is within 24” (0.6 m) of the vertical edge of the door or the bottom exposed edge is less than 60” (1.5 m) above the walking surface Areas where the glass is adjacent to walking surfaces and the bottom edge of the glass is within 18” of the floor Areas where the top edge of the glass is more than 36” above the floor Glazed panels greater than 9 ft.2 (0.8 m2) Local building code authorities may have additional safety glazing requirements, which can vary by state, county, city, town, or borough. Because each state has either adopted the model federal safety building code, modified it in some sections, or written completely new sections, it is imperative to be guided by the particular state or local building code where the glass is to be installed. For detailed information, please contact your local code officials.`,
    body: (
      <>
        <p>
          The Consumer Products Safety Commission establishes the federal
          standards and requirements for safety architectural glass. Federal
          safety glazing law requires the use of safety glass in defined
          hazardous locations, designated by the Model Building Codes.
          Generally, these include:
        </p>

        <ul>
          <li>Bath and shower enclosures</li>
          <li>
            All doors, including storm doors, sliding doors, and combination
            doors
          </li>
          <li>
            Sidelites, including and any glazing next to a door or an opening
            for human passage, if the nearest exposed edge is within 24” (0.6 m)
            of the vertical edge of the door or the bottom exposed edge is less
            than 60” (1.5 m) above the walking surface
          </li>
          <li>
            Areas where the glass is adjacent to walking surfaces and the bottom
            edge of the glass is within 18” of the floor
          </li>
          <li>
            Areas where the top edge of the glass is more than 36” above the
            floor
          </li>
          <li>Glazed panels greater than 9 ft.2 (0.8 m2)</li>
        </ul>

        <p>
          Local building code authorities may have additional safety glazing
          requirements, which can vary by state, county, city, town, or borough.
          Because each state has either adopted the model federal safety
          building code, modified it in some sections, or written completely new
          sections, it is imperative to be guided by the particular state or
          local building code where the glass is to be installed. For detailed
          information, please contact your local code officials.
        </p>
      </>
    ),
  },
  {
    title: "What is Toughened Glass?",
    raw_body: ``,
    body: (
      <>
        <p>
          Toughened glass is another name for tempered glass. See Tempered Glass
          for more info.
        </p>
      </>
    ),
  },
  {
    title: "What is laminated glass? Is it safety glass?",
    raw_body: `Laminated glass contains at least two pieces of glass and one plastic interlayer (PVB, EVA, or an ionoplast polymer), though it can be made with more layers if needed. Laminated glass can be made up with annealed glass, heat-strengthened glass, tempered glass or a combination of these. When laminated glass breaks, the pieces remain attached to the film, preventing the glass from flying or falling and causing injuries or damage. Additional benefits of laminated glass include improved UV and sound control. Various decorative features can be incorporated into laminated glass, including virtually unlimited custom color, textured pattern glass, and interlayer options, are also available. Laminated glass is safety glass when it meets the requirements for ANSI Z97.1 and/or CPSC 16 CFR 1201 and is typically labeled with a “safety” logo identifying the fabricator, glass type, and the standard it meets.`,
    body: (
      <>
        <p>
          Laminated glass contains at least two pieces of glass and one plastic
          interlayer (PVB, EVA, or an ionoplast polymer), though it can be made
          with more layers if needed.
        </p>

        <p>
          Laminated glass can be made up with annealed glass, heat-strengthened
          glass, tempered glass or a combination of these. When laminated glass
          breaks, the pieces remain attached to the film, preventing the glass
          from flying or falling and causing injuries or damage. Additional
          benefits of laminated glass include improved UV and sound control.
          Various decorative features can be incorporated into laminated glass,
          including virtually unlimited custom color, textured pattern glass,
          and interlayer options, are also available.
        </p>

        <p>
          Laminated glass is safety glass when it meets the requirements for
          ANSI Z97.1 and/or CPSC 16 CFR 1201 and is typically labeled with a
          “safety” logo identifying the fabricator, glass type, and the standard
          it meets.
        </p>
      </>
    ),
  },
  {
    title: "What is Laminated Tempered Glass?",
    raw_body: `Laminated tempered glass combines two pieces of tempered glass with a
          plastic interlayer to create a sandwiched construction. When broken,
          the tempered glass will shatter into very small pieces and are held
          together by the plastic interlayer. The lamination reduces the
          possibility of loose, tiny glass shards from flying around. Laminated
          tempered glass is used in glass railings, skylights, overhead
          canopies, and glass stairs.`,
    body: (
      <>
        <p>
          Laminated tempered glass combines two pieces of tempered glass with a
          plastic interlayer to create a sandwiched construction. When broken,
          the tempered glass will shatter into very small pieces and are held
          together by the plastic interlayer. The lamination reduces the
          possibility of loose, tiny glass shards from flying around. Laminated
          tempered glass is used in glass railings, skylights, overhead
          canopies, and glass stairs.
        </p>
      </>
    ),
  },
  {
    title: "What is heat treated glass?",
    raw_body: `The term “heat treated glass” refers to tempered (safety) glass and/or
          heat-strengthened (non-safety) glass.`,
    body: (
      <>
        <p>
          The term “heat treated glass” refers to tempered (safety) glass and/or
          heat-strengthened (non-safety) glass.
        </p>
      </>
    ),
  },
  {
    title: "What is back-painted glass?",
    raw_body: `As the name suggests, back-painted glass is glass that has been
          painted on the back side. Back-painted glass can be tempered or
          laminated. Commercial back painted glass utilizes special water-based
          paints in a controlled clean room environment with special paint guns.
          In addition, commercial back painted glass has a durable, protective
          paint layer over the colored layer to not only protect the colored
          layer, but also to provide a durable surface that adhesive can durably
          stick to when mounted to horizontal or vertical surfaces. Back painted
          glass applications include wall cladding, elevator interiors,
          backsplashes, and counter tops.`,
    body: (
      <>
        <p>
          As the name suggests, back-painted glass is glass that has been
          painted on the back side. Back-painted glass can be tempered or
          laminated. Commercial back painted glass utilizes special water-based
          paints in a controlled clean room environment with special paint guns.
          In addition, commercial back painted glass has a durable, protective
          paint layer over the colored layer to not only protect the colored
          layer, but also to provide a durable surface that adhesive can durably
          stick to when mounted to horizontal or vertical surfaces. Back painted
          glass applications include wall cladding, elevator interiors,
          backsplashes, and counter tops.
        </p>
      </>
    ),
  },
  {
    title: "What is an Interlayer?",
    raw_body: `The interlayer is the plastic used in between pieces of glass to make
          laminated glass. Common interlayer material is polyvinyl butyral
          (PVB), ethylene vinyl acetate (EVA), and an ionoplast polymer such as
          Sentryglas SentryGlas®. Each material is suited for different
          applications.`,
    body: (
      <>
        <p>
          The interlayer is the plastic used in between pieces of glass to make
          laminated glass. Common interlayer material is polyvinyl butyral
          (PVB), ethylene vinyl acetate (EVA), and an ionoplast polymer such as
          Sentryglas SentryGlas®. Each material is suited for different
          applications.
        </p>
      </>
    ),
  },
  {
    title: "What is Obscure Glass?",
    raw_body: `Obscure glass is not completely transparent but has some level of
          distortion which can be created using texture, patterns, or decorative
          interlayers. Obscure glass is typically where privacy is needed, and
          some light is desired.`,
    body: (
      <>
        <p>
          Obscure glass is not completely transparent but has some level of
          distortion which can be created using texture, patterns, or decorative
          interlayers. Obscure glass is typically where privacy is needed, and
          some light is desired.
        </p>
      </>
    ),
  },
  {
    title: "What is Patterned Glass?",
    raw_body: `In the architectural and decorative glass industry, pattern glass has
          one side that features a design created by rolling the hot glass
          ribbon between two metal rollers. The type of design and its structure
          determine the level of translucence of the patterned glass. Depending
          on its use, it can be laminated, back painted and custom designed for
          a variety of uses.`,
    body: (
      <>
        <p>
          In the architectural and decorative glass industry, pattern glass has
          one side that features a design created by rolling the hot glass
          ribbon between two metal rollers. The type of design and its structure
          determine the level of translucence of the patterned glass. Depending
          on its use, it can be laminated, back painted and custom designed for
          a variety of uses.
        </p>
      </>
    ),
  },
  {
    title: "Can Patterned Glass Be Tempered?",
    raw_body: `Some patterned glass can also be tempered if the glass is thick enough
          for durability to ensure safety and security purposes.`,
    body: (
      <>
        <p>
          Some patterned glass can also be tempered if the glass is thick enough
          for durability to ensure safety and security purposes.
        </p>
      </>
    ),
  },
].map((m, i) => ({ ...m, key: i + 1 }))
